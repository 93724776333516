<template>
  <DataTable :value="users">
    <Column field="Id" header="ID" headerStyle="width: 3rem"></Column>
    <Column field="Email" header="Email"></Column>
    <Column field="DisplayName" header="Display Name"></Column>
    <Column header="Accounts">
      <template #body="s">
        <template v-if="s.data.Accounts">
          {{ s.data.Accounts.length }}
        </template>
        <template v-else>
          &mdash;
        </template>
      </template>
    </Column>
    <Column field="Created" header="Created">
      <template #body="s">
        <template v-if="s.data.Created.Valid">{{ printFormat('datetime', s.data.Created.Time, false) }}</template>
        <template v-else>&mdash;</template>
      </template>
    </Column>
    <Column field="Lastlogin" header="Last Login">
      <template #body="s">
        <template v-if="s.data.Lastlogin.Valid">{{ printFormat('datetime', s.data.Lastlogin.Time, false) }}</template>
        <template v-else>&mdash;</template>
      </template>
    </Column>
    <Column headerStyle="width: 7rem">
      <template #body="s">
        <Button icon="pi pi-user-edit" class="p-button-rounded p-button-text"
                @click="$router.push({name:'edit-user', params:{userid: s.data.Id}})"/>
      <Button icon="pi pi-user-minus" class="p-button-rounded p-button-text" @click="doDeleteUser($event, s.data)"/>
      </template>
    </Column>
  </DataTable>
    <ConfirmPopup></ConfirmPopup>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import UACService from "@/services/uac";
import ConfirmPopup from 'primevue/confirmpopup';

export default {
  name: "ListUsers",
  components: {DataTable, Column, ConfirmPopup},
  service: null,
  data() {
    return {
      users: [],
    }
  },
  created() {
    this.service = new UACService();
    this.loadUsers()
  },
  methods: {
    loadUsers: function() {
        this.users = [];
        this.service.getUsers().then((res) => {
            this.users = res
        })
    },
    doDeleteUser: function (event, user) {
        this.$confirm.require({
            target: event.currentTarget,
            message: 'Are you sure you want to delete ' +user.Email+ ' ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.service.deleteUser(user.Id).then(res => {
                    if (res.message === 'ok') {
                        this.$toast.add({severity:'success', summary: 'Success Message', detail:'User deleted', life: 3000})
                        this.loadUsers()
                    }
                })
            }
        });
    },
  }
}
</script>